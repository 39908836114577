import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPuzzlePiece, faGamepad, faBlog, faSignInAlt, faUserPlus, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {

  const navigate = useNavigate();
  let auth;

  try {
    auth = JSON.parse(localStorage.getItem("key"));
  } catch (e) {
    auth = null;
  }

  const logout = () => {
    localStorage.clear("key");
    navigate("/login");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/"><FontAwesomeIcon icon={faHome} /> Jigsaw Planet</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Align nav links to the left */}
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {auth && auth.role === "admin" && (
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/dashboard">
                    <FontAwesomeIcon icon={faHome} /> Dashboard
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  <FontAwesomeIcon icon={faHome} /> Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/puzzle1">
                  <FontAwesomeIcon icon={faPuzzlePiece} /> Jigsaw Puzzle
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/freenova">
                  <FontAwesomeIcon icon={faGamepad} /> Freeze Nova
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/free">
                  <FontAwesomeIcon icon={faGamepad} /> Gaming Theme
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blog">
                  <FontAwesomeIcon icon={faBlog} /> Gaming Blog
                </Link>
              </li>
            </ul>
            <form className="d-flex">
              {
                auth ?
                  <button onClick={logout} className="btn btn-danger" type="submit">
                    <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                  </button>
                  :
                  <>
                    <Link to="/login" className="btn btn-danger" type="submit">
                      <FontAwesomeIcon icon={faSignInAlt} /> Login
                    </Link>
                    <Link to="/register" className="btn btn-danger mx-2" type="submit">
                      <FontAwesomeIcon icon={faUserPlus} /> Register
                    </Link>
                  </>
              }
            </form>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar;
