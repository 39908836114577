let Blogsdb = [
  {
    id: 1,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from jigsaw Game"
    },
    title: "Jigsaw planet free",
    description:
      "Jigsaw planet games come in many varieties, each with unique mechanics and objectives.",
    category: "Jigsaw Game . Fun and Entertainment",
    image: "./images/Puzzle1.jpeg",
  },
  {
    id: 2,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from Puzzle Game"
    },
    title: "Jigsawplanet Game",
    description:
      "Free Jigsaw planet come in many varieties, each with unique mechanics and objectives.",
    category: "Puzzle Game . Fun and Entertainment",
    image:
      "./images/Puzzle2.jpeg",
  },
  {
    id: 3,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from Jigsaw planet Game"
    },
    title: "Jigsawplanet",
    description:
      "jigsaw planet free come in many varieties, each with unique mechanics and objectives.",
    category: "Puzzle Game . Fun and Entertainment",
    image:
      "./images/Puzzle3.jpeg",
  },
  {
    id: 4,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from jigsaw Game"
    },
    title: "Jigsaw puzzle",
    description:
      "jigawplanet puzzles come in many varieties, each with unique mechanics and objectives.",
    category: "Puzzle Game . Fun and Entertainment",
    image:
      "./images/Puzzle5.jpeg",
  },
  {
    id: 5,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from freeze nova free Game"
    },
    title: "Freeze nova Game",
    description:
      "Freezenova free come in many varieties, each with unique mechanics and objectives.",
    category: "Freezenova Game . Fun and Entertainment",
    image:
      "./images/Freezenova1.jpeg",
  },
  {
    id: 6,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from freezenova Game"
    },
    title: "Freezenova Game",
    description:
      "Jigsawplanet free games come in many varieties, each with unique mechanics and objectives.",
    category: "Jigsaw free Game . Fun and Entertainment",
    image:
      "./images/Freezenova2.jpeg",
  },
  {
    id: 7,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from jigsawplanet Game"
    },
    title: "Freezenova Game",
    description:
      "jigsaw planet games come in many varieties, each with unique mechanics and objectives.",
    category: "Free jigsaw Game . Fun and Entertainment",
    image:
      "./images/Freezenova3.jpeg",
  },
  {
    id: 8,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from jigsaw company Game"
    },
    title: "Freezenova Game",
    description:
      "Jigsawplanet free games come in many varieties, each with unique mechanics and objectives.",
    category: "Jigsawplanet Game . Fun and Entertainment",
    image:
      "./images/Freezenova4.jpeg",
  },
  {
    id: 9,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from jigsawplanet free"
    },
    title: "Freezenova Game",
    description:
      "Freezenova games come in many varieties, each with unique mechanics and objectives.",
    category: "Jigsaw Game . Fun and Entertainment",
    image:
      "./images/Freezenova5.jpeg",
  },
  {
    id: 10,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from free jigsaw planet Game"
    },
    title: "Freezenova Game",
    description:
      "Freezenova games come in many varieties, each with unique mechanics and objectives.",
    category: "Free jigsawplanet Game . Fun and Entertainment",
    image:
      "./images/Freezenova6.jpeg",
  },
  {
    id: 11,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from jigsaw planet Game"
    },
    title: "Freezenova Game",
    description:
      "Jigsaw free games come in many varieties, each with unique mechanics and objectives.",
    category: "Jigsaw planet Game . Fun and Entertainment",
    image:
      "./images/Freezenova7.jpeg",
  },
  {
    id: 12,
    Details: {
      Author: "Salman Blogger",
      Released: "July 2024",
      Credits: "Images from jigsawplant Game"
    },
    title: "Freezenova Game",
    description:
      "Jigsawplanet games come in many varieties, each with unique mechanics and objectives.",
    category: "Free jigsawplanet Game . Fun and Entertainment",
    image:
      "./images/Freezenova8.jpeg",
  },


];

export default Blogsdb;